.fc-calendar-wrap .fc-header {
    padding: 0 0 10px 0;
    border-bottom: 1px solid rgba(0,0,0,0.13);
}
.fc-calendar-wrap .fc-header h3, .fc-header h4 {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}
.fc-calendar-wrap .fc-header h3 {
    font-size: 18px;
    margin: 0 0 2px 0;
}
.fc-calendar-wrap .fc-header h4 {
    font-size: 10px;
    color: rgba(0,0,0,0.5);
}

.fc-calendar-wrap .fc-header nav {
    position: relative;
    height: 0;
}
.fc-calendar-wrap .fc-header nav span {
    position: absolute;
    padding: 0px 15px;
    cursor: pointer;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.fc-calendar-wrap .fc-header nav .fc-prev {
    left: 0;
}
.fc-calendar-wrap .fc-header nav .fc-next {
    right: 0;
}

.fc-calendar-wrap .fc-head {
    height: 30px;
}
.fc-calendar-wrap .fc-head .fc-cell {
    float: left;
    width: 14.28%;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
.fc-calendar-wrap .fc-head .fc-cell .fc-sm-wk-abbr {
    display: none;
}

.fc-calendar-wrap .fc-body {
    border: 1px solid rgba(0,0,0,.13);
}
.fc-calendar-wrap .fc-body .fc-row {
    border-bottom: 1px solid rgba(0,0,0,0.13);
    height: 50px;
}
.fc-calendar-wrap .fc-body .fc-row:last-child {
    border-bottom: none;
}
.fc-calendar-wrap .fc-body .fc-cell {
    float: left;
    width: 14.28%;
    height: 50px;
    border-right: 1px solid rgba(0,0,0,.13);
    position: relative;
}
.fc-calendar-wrap .fc-body .fc-row div:last-of-type {
    border-right: none;
}
.fc-calendar-wrap .fc-body .fc-cell .fc-weekday {
    display: none;
}
.fc-calendar-wrap .fc-body .fc-cell .fc-date {
    position: absolute;
    width: 30px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin:-10px 0 0 -15px;
    left: 50%;
    top: 50%;
}
.fc-calendar-wrap .fc-body .fc-row .fc-cell {
    cursor: pointer;
}
.fc-calendar-wrap .fc-body .fc-row .fc-cell:empty {
    background: rgba(0,0,0,.05);
    cursor: default;
}
.fc-calendar-wrap .fc-body .fc-today {
    background:#333333;
}
.fc-calendar-wrap .fc-body .fc-today .fc-date {
    color:#fff;
}

.fc-calendar-wrap .fc-content .fc-data {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 6px;
}
.fc-calendar-wrap .fc-content .fc-data-item .fa-circle,
.fc-calendar-wrap .fc-content .fc-data-item .tnt-circle {
    color: rgba(0,0,0,0.25);
}


.fc-calendar-wrap .fc-body .fc-today .fc-data .fc-data-item .fa-circle,
.fc-calendar-wrap .fc-body .fc-today .fc-data .fc-data-item .tnt-circle {
    color: rgba(255,255,255,0.25);
}
.fc-calendar-wrap .fc-content:hover .fc-data .fa-circle,
.fc-calendar-wrap .fc-content:hover .fc-data .tnt-circle {
    color: rgba(0,0,0,0.75);
}
.fc-calendar-wrap .fc-body .fc-today:hover .fc-data .fa-circle,
.fc-calendar-wrap .fc-body .fc-today:hover .fc-data .tnt-circle {
    color: rgba(255,255,255,0.75);
}

.fc-calendar-wrap .fc-foot {
    border-bottom: 1px solid rgba(0,0,0,.13);
}
.fc-calendar-wrap .fc-foot .fc-foot-item {
    float: left;
    width: 33.33%;
    text-align: center;
    padding: 5px 0;
}

@media screen and (max-width: 400px) {
    .fc-calendar-wrap .fc-body .fc-cell .fc-date {
        font-size: 15px;
    }
}


.fc-calendar-wrap.squery-260 .fc-header nav span {
    padding: 0;
}
.fc-calendar-wrap.squery-260 .fc-header h3 {
    font-size: 16px;
    font-weight: bold;
    padding-top: 2px;
}
.fc-calendar-wrap.squery-260 .fc-head .fc-cell .fc-wk-abbr {
    display: none;
}
.fc-calendar-wrap.squery-260 .fc-head .fc-cell .fc-sm-wk-abbr {
    display: inline;
}
.fc-calendar-wrap.squery-260 .fc-body .fc-cell .fc-date {
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    margin:-6px 0 0 -15px;
}
.fc-calendar-wrap.squery-260 .fc-content .fc-data-item .fa-circle:nth-child(2),
.fc-calendar-wrap.squery-260 .fc-content .fc-data-item .fa-circle:nth-child(3),
.fc-calendar-wrap.squery-260 .fc-content .fc-data-item .tnt-circle:nth-child(2),
.fc-calendar-wrap.squery-260 .fc-content .fc-data-item .tnt-circle:nth-child(3) {
    display: none;
}
.fc-calendar-wrap.squery-260 .fc-foot .fc-foot-item {
    float: none;
    width: auto;
    padding: 1px 0;
}